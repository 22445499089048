import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

export const SHARED_ZORRO_MODULES = [
  NzLayoutModule,
  NzMenuModule,
  NzInputModule,
  NzButtonModule,
  NzFormModule,
  NzIconModule,
  NzPageHeaderModule,
  NzTableModule,
  NzSelectModule,
  NzPopoverModule,
  NzGridModule,
  NzTabsModule,
  NzTagModule,
  NzDropDownModule,
  NzModalModule,
  NzDatePickerModule,
  NzMessageModule,
  NzCardModule,
  NzListModule,
  NzTypographyModule,
  NzBadgeModule,
  NzAlertModule,
  NzAvatarModule,
  NzSwitchModule,
  NzCheckboxModule,
  NzUploadModule,
  NzPopconfirmModule,
  NzDescriptionsModule,
  NzStepsModule,
  NzToolTipModule,
  NzCollapseModule,
  NzCarouselModule,
  NzSpinModule,
  NzCalendarModule,
  NzAutocompleteModule,
  NzDrawerModule,
  NzCommentModule,
  NzMentionModule,
  NzPaginationModule,
  NzEmptyModule,
];
