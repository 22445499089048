import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { User } from '../model/user';

export interface Credentials {
  accessToken?: string;
  refreshToken?: string;
  email?: string;
}

export interface TeamAccess {
  expires: number;
  canLogin: boolean;
  error: boolean;
  message: string;
  validTrialMode: boolean;
}

const credentialsKey = 'CREDENTIALS';
const userKey = 'USER';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private userInfo$: Subject<boolean> = new Subject();
  private CREDENTIALS: Credentials | null = null;
  private USER: User | null = null;

  constructor() {
    const savedCredentials = localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this.CREDENTIALS = JSON.parse(savedCredentials);
    }
    const savedUser = localStorage.getItem(userKey);
    if (savedUser) {
      this.USER = JSON.parse(savedUser);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this.CREDENTIALS;
  }

  /**
   * Gets the user profile.
   * @return The user profile or null if the user is not authenticated.
   */
  get user(): User | null {
    return this.USER;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials): void {
    this.CREDENTIALS = credentials || null;
    this.saveValue(credentialsKey, credentials);
  }

  setUser(user?: User): void {
    this.USER = user || null;
    this.saveValue(userKey, user);
    this.setUserInfoObs(true);
  }

  saveValue(key: string, data?: any): void {
    if (data) {
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      localStorage.removeItem(key);
    }
  }

  getUserInfoObs(): Observable<boolean> {
    return this.userInfo$.asObservable();
  }

  setUserInfoObs(reload: boolean): void {
    this.userInfo$.next(reload);
  }

  /**
   * Clears the user credentials.
   * The user will is no longer authenticated.
   */
  clearCredentials(): void {
    this.CREDENTIALS = null;
    this.USER = null;
    localStorage.removeItem(credentialsKey);
    localStorage.removeItem(userKey);
  }
}
