import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { CredentialsService } from './credentials.service';
import { OrganizationService } from 'src/app/shared/services/organization/organization.service';
import { Observable, Observer, firstValueFrom, from, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionGuard implements CanActivate {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private organizationService: OrganizationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const user = this.credentialsService.user;
    const organizationId = user?.currentOrganization?.organizationId;
    if (organizationId) {
      return from(
        this.organizationService.verifySubscription(organizationId)
      ).pipe(
        map((validSubscription) => {
          // Stripe Step 1: user does not have a valid subscription, so they need to go to signup
          if (!validSubscription) {
            this.router.navigate(['/auth/payment']);
            return false;
          }
          return true;
        })
      );
    } else {
      // todo: need to determine if this is proper route or if they should go to org creation screen
      return of(true);
    }
  }
}
