export const PasswordValidator = {
  error: 'The password must have at least 6 characters and 1 Uppercase, 1 lowercase and 1 number.',
  pattern: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
  min: 6,
  max: 32,
}
export const PhoneValidator = {
  pattern: '[0-9]{3}[0-9]{3}[0-9]{4}',
  mask: '000-000-0000',
  error: 'The phone format must be: 000-000-0000',
  specialCharacters: ['-'],
}

export const FINDALL = {
  take: 999999,
  sortField: 'id',
  sortOrder: 'desc',
}
export enum OperatingSystems {
  WINDOWS= 1,
  MACOS= 2,
  LINUX= 3,
  IOS= 4,
  ANDROID= 5,
  OTHER= 6
}

export enum Browsers {
  GOOGLE_CHROME= 1,
  FIREFOX= 2,
  SAFARI= 3,
  OPERA= 4,
  INTERNET_EXPLORER= 5,
  EDGE= 6,
  NATIVE_APP= 7,
  OTHER= 8,
}

export const getOperatingSystem = (): number => {
  let os = null;
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Darwin'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OperatingSystems.MACOS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OperatingSystems.IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OperatingSystems.WINDOWS;
  } else if (/Android/.test(userAgent)) {
    os = OperatingSystems.ANDROID;
  } else if (!os && /Linux/.test(platform)) {
    os = OperatingSystems.LINUX;
  } else {
    os = OperatingSystems.OTHER;
  }
  return os;
};

export const getBrowser = (): number => {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return Browsers.OPERA;
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return Browsers.GOOGLE_CHROME;
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    return Browsers.SAFARI;
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return Browsers.FIREFOX;
  } else {
    return Browsers.OTHER;
  }
};

export const isMobile = () => {
  let resp = false;
  if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
    resp = true;
  }
  return resp;
};


export const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const toCamel = (str: string): string => {
  const camel = str.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
  return camel.charAt(0).toLowerCase() + camel.slice(1);
};

const isObject = (obj: any) => {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};

export const keysToCamel = (obj: any): any => {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj)
      .forEach((k: string) => {
        // @ts-ignore
        n[toCamel(k)] = keysToCamel(obj[k]);
      });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return keysToCamel(i);
    });
  }

  return obj;
};

/**
 * Download file base on the url
 *
 * @param url
 * @param fileName
 * @returns
 */
export const downloadFile = async (url: string, fileName: string): Promise<void> => {
  if (url.length <= 0) {
    return Promise.reject('Url needts to be set.');
  }
  const res = await fetch(url, { mode: 'no-cors'});
  const blob = await res.blob();
  const link = document.createElement('a');
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  link.click();
};

export const isDateToday = (inputDate: Date): boolean => {
  return inputDate.setHours(0,0,0,0) == (new Date()).setHours(0,0,0,0);
}
export const removeNulls = (obj: any): any => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export const objectToFormData = (form: any): FormData => {
  let formData = new FormData();
  Object.keys(form).forEach((key: string) => {
    if (Array.isArray(form[key])) {
      form[key].forEach((file: Blob) => {
        // @ts-ignore
        formData.append(key, file);
      })
    } else {
      // @ts-ignore
      formData.append(key, form[key]);
    }
  })
  return formData;
}

export const isNotEmpty = (field?: string): boolean => {
  return !!field && field.length > 0;
}

export const openBase64 = (file: string): void => {
  let pdfWindow = window.open('');
  pdfWindow!.document.title = 'Hellcat | Daily Summary';
  pdfWindow!.document.write(
    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
    encodeURI(file) + "'></iframe>"
  )
}
