import { OrganizationUser } from "../../shared/models/organization";

export const GeneralEmployee = 'General Employee';

export enum ROLES {
  Master = "Master",
  Foreman = "Foreman",
  Employee = "Employee"
}

export enum UserStatus {
  PendingVerification = 'PendingVerification',
  PendingInformation = 'PendingInformation',
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface UserProfile {
  id?: number;
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  birthDay?: string;
  city?: string;
  company?: string;
  phone?: string;
  address?: string;
  zip?: string;
  state?: string;
  securityPin?: string;
  avatar?: string;
  profileImage?: string;
  skills: string[];
}

export interface UserDevice {
  id: number;
  userId: number;
  playerUuid: string;
  deviceType: string;
}

export interface User {
  email: string;
  id: number;
  firstName: string;
  lastName: string;
  isEnabled: true,
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
  currentOrganization?: OrganizationUser;
  organizationUser: OrganizationUser[];
  welcomePage?: string;
  status: UserStatus;
  fullName?: string;
  userProfile?: UserProfile;
  userDevice: UserDevice[];
  profileImage?: string;

  // soft deleted users
  isDeleted?: boolean;
  deletedBy?: number;

  //frontend - we use only to display the role
  role?: string;
  fullProfile?: boolean;
  officeUser?: boolean;
}

export interface UserListResponse {
  items: User[];
  total: number;
}

export interface OauthTokenRequest {
  email: string;
  password: string;
}
