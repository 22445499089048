import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {CredentialsService} from "../auth/services/credentials.service";

@Injectable({
  providedIn: 'root',
})
export class WelcomeGuard implements CanActivate {

  constructor(
    private router: Router,
    private credentialsService: CredentialsService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.credentialsService.user;
    if (user) {
      if (!user?.currentOrganization) {
        return true;
      } else {
        this.router.navigate(['/dashboard'], {queryParams: {redirect: state.url}, replaceUrl: true});
        return false;
      }
    } else {
      this.router.navigate(['/auth/login'], {queryParams: {redirect: state.url}, replaceUrl: true});
      return false;
    }
  }

}
