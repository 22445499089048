// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'https://api.dev.hellcat.tech/',
  timeout: 20000,
  apiKey: 'ABCDEF-0123456789',
  oneSignal: '3d49616e-a2f1-4d91-81b6-a2edcd045333',
  firebase: {
    apiKey: 'AIzaSyBVL1qANFMjiEanOSbjmkXO1hFlSKdcndo',
  },
  paymentTableId: 'prctbl_1NugwdFKvkcag32aHhCB9d1E',
  stripePublishableKey:
    'pk_test_51MTwmnFKvkcag32a8xlj3zNSWG3FJmuhwpZqQfys8SKAktWkAjMqK8m0Odwb5yh9ClqNeWp39sBeMWCgU2HdDDNf00Ww7tXY1f',
  links: {
    webapp: 'https://web.dev.hellcat.tech/',
    android: 'hellcat://',
    ios: 'hellcat://',
    playStore:
      'https://play.google.com/store/apps/details?id=com.hellcattechnologies.hellcatfieldcommand',
    appStore:
      'https://apps.apple.com/us/app/hellcat-field-command/id1634492609',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
