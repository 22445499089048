import {AssignedUser, WorkOrder, WorkOrderStatus, WorkType} from "../../models/work-orders";
import {keysToCamel} from "../../common-services/utils";
import {User} from "../../../auth/model/user";
import {WorkOrderNote} from "../../models/work-order-note";

export const completeWorkOrderList = (list: WorkOrder[]): WorkOrder[] => {
  const response: WorkOrder[] = [];
  list.forEach((workOrder: WorkOrder) => {
    workOrder = completeWorkOrder(keysToCamel(workOrder));
    response.push(workOrder);
  });
  return response;
};

export const getWorkOrderStatusText = (status: WorkOrderStatus): string => {
  if (status === WorkOrderStatus.Queued) {
    return 'Work Queue'
  } else {
    return status.toString();
  }
}

export const getWorkOrderStatusColor = (status: WorkOrderStatus): string => {
  switch (status) {
    case WorkOrderStatus.Queued:
      return "#31CCB0";
    case WorkOrderStatus.InProgress:
      return "#29ABE2";
    case WorkOrderStatus.Submitted:
      return "#F7931E";
    case WorkOrderStatus.SentForPayment:
    case WorkOrderStatus.Paid:
      return "#031b62";
    case WorkOrderStatus.Accepted:
      return "#46B727";
    case WorkOrderStatus.Archived:
    case WorkOrderStatus.Rejected:
      return "#FF2F06";
    case WorkOrderStatus.Closed:
      return "#93278F";
  }
}

export const getWorkOrderFlagColor = (dueDate?: string): string => {
  if (!dueDate) {
    return '#ffffff';
  }
  let tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const due = new Date(dueDate);
  due.setHours(0, 0, 0, 0);
  const today = new Date(new Date().getTime() + tzOffset);
  today.setHours(0, 0, 0, 0);
  const date3 = new Date(new Date().getTime() + tzOffset);
  date3.setDate(date3.getDate() + 3);
  date3.setHours(0, 0, 0, 0);
  if (due < today) {
    return "#FF2F06";
  } else if (due <= date3) {
    return "#F7931E";
  } else {
    return "#46B727";
  }

}


export const completeWorkOrderNote = (workOrderNotes: WorkOrderNote[]): WorkOrderNote[] => {
  if (workOrderNotes && workOrderNotes.length) {
    workOrderNotes.forEach((w) => {
      w.replies = [];
      if (w.createdBy) {
        w.user = userToAssignedUser(w.createdBy);
      }
    })
  }
  return workOrderNotes;
}

export const userToAssignedUser = (user: User): AssignedUser => {
  const fullname = `${user.firstName} ${user.lastName}`
  const noe = fullname ? fullname : user.email;
  const isEnabled = user.organizationUser?.[0]?.isEnabled;
  const ass: AssignedUser = {
    id: user.id,
    avatar: noe.split(' ').slice(0, 2).map((x) => x.charAt(0)).join(''),
    email: user.email,
    text: noe,
    image: user.userProfile ? user.userProfile.profileImage : undefined,
    isDeleted: user.isDeleted ? user.isDeleted : false,
    isEnabled: isEnabled,
  }
  return ass;
}

export const completeWorkOrder = (workOrder: WorkOrder): WorkOrder => {
  workOrder.workOrderTypeIds = [];
  workOrder.workOrderUserIds = [];
  workOrder.contractors = [];

  if (workOrder.dueDate) {
    let tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
    workOrder.dueDate = new Date(new Date(workOrder.dueDate).getTime() + tzOffset).toString();
    workOrder.dueDateObj = new Date(workOrder.dueDate);

  }

  if(![WorkOrderStatus.Closed ,WorkOrderStatus.SentForPayment].includes(workOrder.status))
  workOrder.flagColor = getWorkOrderFlagColor(workOrder.dueDate);
  else
  workOrder.flagColor = "#000000"

  if (workOrder.workOrderWorkType && workOrder.workOrderWorkType.length) {
    workOrder.workOrderWorkType.forEach((w) => {
      workOrder.workOrderTypeIds.push(w.workTypeId);
    })


    workOrder.workOrderWorkType = workOrder.workOrderWorkType.map((e) => {
      if(!e.workType) return {...e};
      e.workType.label = `${e.workType.workType}${e.workType.status === 'Active'? '' : ' (Inactive)'}`;
      return {...e}
    })
  }

  if (workOrder.workSourceChilds) {
    workOrder.contractors = workOrder.workSourceChilds.map((w) => w.assignedOrganizationId);
  }



  if (workOrder.phoneNumber) {
    workOrder.phoneNumber = workOrder.phoneNumber.replace(/-/g, "");
  }

  workOrder.workOrderBillableUnit.forEach(wobu => {
    wobu.totalPrice = wobu.quantity * wobu.billableUnit.price;
  })

  workOrder.workOrderBillableUnitTotal = workOrder.workOrderBillableUnit.reduce((sum, wobu) => sum += wobu.totalPrice, 0);

  workOrder.fieldUsers = [];
  if (workOrder.workOrderCrew && workOrder.workOrderCrew.length) {
    workOrder.workOrderCrew.forEach((w: any) => {
      if (w.crew) {
        const ass = userToAssignedUser(w.crew.crewLead);
        workOrder.fieldUsers.push(ass);
      }
    })
  }

  workOrder.assignedUsers = [];
  if (workOrder.workOrderUser && workOrder.workOrderUser.length) {
    workOrder.workOrderUser.forEach((w) => {
      if (w.user) {
        const ass = userToAssignedUser(w.user);
        workOrder.assignedUsers.push(ass);
        workOrder.workOrderUserIds.push(w.userId);
      }
    })
  }

  if (workOrder.status) {
    workOrder.statusColor = getWorkOrderStatusColor(workOrder.status);
    workOrder.statusText = getWorkOrderStatusText(workOrder.status);
  }

  if (workOrder.workAddress) {
    workOrder.fullAddress = `${workOrder.workAddress}, ${workOrder.workCity}, ${workOrder.workState}, ${workOrder.workZip}`
  }

  workOrder = removeTimeZone(workOrder);

  return workOrder;
};

export const removeTimeZone = (workOrder: WorkOrder): WorkOrder => {
  try {
    const dateFields = ['dueDate', 'createdAt', 'updatedAt'];
    for (const key in workOrder) {
      const keyObject: {[index: string]:any} = workOrder;
      if (dateFields.includes(key) && keyObject[key]) {
        let stringDate: string = keyObject[key];
        if (stringDate.includes('Z')) {
          stringDate = stringDate.replace('Z','');
        }
        keyObject[key] = new Date(stringDate);
      }
    }
  } catch (e) {
    console.error(e);
  }
  return workOrder;
}
