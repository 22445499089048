import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {CredentialsService} from "./credentials.service";
import {environment} from "../../../environments/environment";

enum TypeInterceptor {
  TOKEN = "TOKEN",
  APIKEY = "APIKEY"
}


@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  apiKey = environment.apiKey;
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;

    if (this.shouldInterceptApiKey(req)) {
      request = this.cloneRequest(req, TypeInterceptor.APIKEY);
    } else if (this.shouldInterceptToken(req)) {
      request = this.cloneRequest(req, TypeInterceptor.TOKEN);
    }

    return next.handle(request).pipe(catchError((err: any) => {
      if (err.status === 401) {
        this.credentialsService.setUser();
        this.credentialsService.setCredentials();
        const noRedirect = 'auth/invitation/verify';
        if(!this.router.url.includes(noRedirect)){
          this.router.navigate(['/auth/login']);
        }

        return throwError(err);
      } else {
        return throwError(err);
      }
    }))
  }

  private cloneRequest(req: HttpRequest<any>, type: TypeInterceptor): HttpRequest<any> {
    let final = req;
    if (type == TypeInterceptor.TOKEN && this.credentialsService && this.credentialsService.credentials && this.credentialsService.credentials.accessToken) {
      const authorization = `Bearer ${this.credentialsService.credentials.accessToken}`;
      final = req.clone({setHeaders: {authorization}});
    }
    else if (type == TypeInterceptor.APIKEY) {
      const authorization = this.apiKey;
      final = req.clone({setHeaders: {authorization}});
    }
    return final;
  }

  /**
   * Determinates if is necessary to intercept the request
   */
  private shouldInterceptToken(req: HttpRequest<any>): boolean {
    const exceptions = ['invitation/get-by-token'];
    const some = exceptions.some( (x) => req.url.indexOf(x) >= 0)
    return (!some);
  }

  /**
   * Determinates if is necessary to intercept the request
   */
  private shouldInterceptApiKey(req: HttpRequest<any>): boolean {
    const exceptions = ['invitation/get-by-token'];
    const some = exceptions.some( (x) => req.url.indexOf(x) >= 0)
    return (some);
  }

}
