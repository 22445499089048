import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject,  } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HideService {
  TOHIDKEY = 'toHide';
  toHide = new BehaviorSubject<any>({
    financials: false
  });

  constructor(
    private router: Router
  ) {
    if(localStorage.getItem(this.TOHIDKEY)) {
      this.toHide.next(JSON.parse(localStorage.getItem(this.TOHIDKEY) || '{}'))
    } else {
      this.toHide.next({
        financials: false
      })
      localStorage.setItem(this.TOHIDKEY,JSON.stringify({
        financials: false
      }))
    }
  }

  enableDisable(key: string) {
    if(! (key in this.toHide.getValue())) return

    let oldVal = this.toHide.getValue()
    oldVal[key] = !oldVal[key];
    localStorage.setItem(this.TOHIDKEY, JSON.stringify(oldVal));
    this.toHide.next(oldVal)
  }

  removeToHideLocalStorage() {
    localStorage.removeItem(this.TOHIDKEY);
  }


}
