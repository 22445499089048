import {keysToCamel} from "../../common-services/utils";
import {Organization, OrganizationCrew, OrganizationUser} from "../../models/organization";
import {userToAssignedUser} from "../work-orders/work-orders.utils";
import {AssociatedContact} from "../../models/work-orders";
import {ROLES} from "../../../auth/model/user";

export const completeOrganizationList = (list: Organization[]): Organization[] => {
  const response: Organization[] = [];
  list.forEach((organization: Organization) => {
    organization = completeOrganizationInfo(organization);
    response.push(organization);
  });
  return response;
};

export const completeOrganizationInfo = (org: Organization): Organization => {
  org = keysToCamel(org);

  const addressInfo: string[] = [];
  const fields = ['address', 'city', 'state', 'zip'];
  fields.forEach((x: string) => {
    // @ts-ignore
    if (typeof org[x] === "string" && org[x] !== '') {
      // @ts-ignore
      addressInfo.push(org[x]);
    }
  })

  org.fullAddress = addressInfo.join(', ');
  return org;
}

export const completeOrganizationUserList = (list: OrganizationUser[]): OrganizationUser[] => {
  const response: OrganizationUser[] = [];
  list.forEach((organizationUser: OrganizationUser) => {
    organizationUser = keysToCamel(organizationUser);
    organizationUser.user.fullName = `${organizationUser.user.firstName} ${organizationUser.user.lastName}`

    organizationUser.user.role = organizationUser.role;
    if(organizationUser.role === ROLES.Master) {
      organizationUser.user.role = organizationUser.organization?.createdBy === organizationUser.user.id ?
        "Super Admin" : "Office User";
    }

    response.push(organizationUser);
  });
  return response;
};


export const completeOrganizationCrewList = (list: OrganizationCrew[]): OrganizationCrew[] => {
  const response: OrganizationCrew[] = [];
  list.forEach((organizationCrew: OrganizationCrew) => {
    organizationCrew = keysToCamel(organizationCrew);
    if (organizationCrew.crewLead) {
      organizationCrew.crewLead = userToAssignedUser(organizationCrew.crewLead as any);
    }

    if (organizationCrew.createdBy) {
      organizationCrew.createdBy = userToAssignedUser(organizationCrew.createdBy as any);
    }

    organizationCrew.crewWorkTypeIds = (organizationCrew.crewWorkType || []).map(x => x.workTypeId);

    organizationCrew.crewUser = organizationCrew.crewUser.map((x: any) => userToAssignedUser((x as any).user));
    response.push(organizationCrew);
  });
  return response;
};

export const completeAssociatedContact = (list: AssociatedContact[]): AssociatedContact[] => {
  const response: AssociatedContact[] = [];
  list.forEach((associatedContact: AssociatedContact) => {
    if(associatedContact.OrganizationB) {
      associatedContact.OrganizationB = completeOrganizationInfo(associatedContact.OrganizationB);
    }
    response.push(associatedContact);
  });
  return response;
};
