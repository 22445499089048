import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  AddUserToOrganization,
  Organization,
  OrganizationConfiguration,
  OrganizationContact,
  OrganizationCrew,
  OrganizationCrewListResponse,
  OrganizationListResponse,
  OrganizationUserListResponse,
} from '../../models/organization';
import { ApiService } from '../../common-services/api.service';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import {
  completeAssociatedContact,
  completeOrganizationCrewList,
  completeOrganizationInfo,
  completeOrganizationList,
  completeOrganizationUserList,
} from './organization.utils';
import {
  AssociatedContact,
  AssociatedContactInfo,
} from '../../models/work-orders';
import { LoginService } from '../../../auth/services/login.service';
import { User } from 'src/app/auth/model/user';
import { FormGroup } from '@angular/forms';

// default configuration here
const CONFIG_DEFAULT: OrganizationConfiguration = {
  foremanCanCreateWo: {
      label: 'Allow Foreman to create a Work Order',
      type: 'toggle',
      value: false,
      required: true,
      formSize: {xs: 24, lg: 24,xl: 24},
      row: true,
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  path = 'organization/';
  associatedContact = 'associated-contact';
  crews = 'crew/';
  organizationContact = 'organization-contact/';
  configuration = '/configuration';
  currentUser: User | null = null;

  orgConfiguration = new BehaviorSubject<OrganizationConfiguration>(
    CONFIG_DEFAULT
  )

  constructor(
    private apiService: ApiService,
    private loginService: LoginService,
  ) {
    this.currentUser =loginService.getCurrentUser();
    if(this.currentUser?.currentOrganization) {
      this.getOrgConfiguration(this.currentUser.currentOrganization?.organization.id);

    }
  }

  createOrganization(organization: Organization): Observable<Organization> {
    return this.apiService.post(environment.api, this.path, organization);
  }

  updateOrganization(
    organization: Partial<Organization>
  ): Observable<Organization> {
    return this.apiService.patch(
      environment.api,
      this.path + organization.id,
      organization
    );
  }

  updateOrganizationLogo(info: any, id: number): Observable<any> {
    return this.apiService
      .submitWithFile('POST', environment.api + this.path + id + '/logo', info)
      .pipe(
        switchMap((r) => {
          return this.loginService.getProfile();
        })
      );
  }

  addUserToOrganization(info: AddUserToOrganization): Observable<any> {
    return this.apiService.post(
      environment.api,
      `${this.path}${info.organizationId}/user`,
      info
    );
  }

  updateOrganizationUser(info: any): Observable<any> {
    return this.apiService.patch(
      environment.api,
      `${this.path}${info.organizationId}/user/${info.userId}`,
      info
    );
  }

  removeUserOfOrganization(info: any): Observable<any> {
    return this.apiService.delete(
      environment.api,
      `${this.path}${info.organizationId}/user/${info.userId}`
    );
  }

  getUsers(params: any, organizationId: number): Observable<any> {
    return this.apiService
      .get(environment.api, `${this.path}${organizationId}/users`, params)
      .pipe(
        switchMap((response: OrganizationUserListResponse) => {
          response.items = completeOrganizationUserList(response.items);
          return of(response);
        })
      );
  }

  getUsersFiltered(params: any, organizationId: number, filters: any): Observable<any> {
    return this.apiService
      .post(environment.api, `${this.path}organization-users-search`, {...filters,organizationId}, params)
      .pipe(
        switchMap((response: OrganizationUserListResponse) => {
          response.items = completeOrganizationUserList(response.items);
          return of(response);
        })
      );
  }

  getOrganizations(
    params: any,
    filters: any
  ): Observable<OrganizationListResponse> {
    return this.apiService
      .post(environment.api, `${this.path}find-all`, filters, params)
      .pipe(
        switchMap((response: OrganizationListResponse) => {
          response.items = completeOrganizationList(response.items);
          return of(response);
        })
      );
  }

  getAssociatedContacts(
    organizationId: number
  ): Observable<AssociatedContact[]> {
    return this.apiService
      .get(environment.api, `${this.path}${organizationId}/associated-contacts`)
      .pipe(
        switchMap((response: AssociatedContact[]) => {
          return of(completeAssociatedContact(response));
        })
      );
  }

  getAssociatedContact(id: number): Observable<AssociatedContactInfo> {
    return this.apiService.get(
      environment.api,
      `${this.associatedContact}/${id}`
    );
  }

  submitAssociatedContact(contact: any): Observable<Organization> {
    if (contact.id) {
      return this.apiService.patch(
        environment.api,
        `${this.associatedContact}/${contact.id}`,
        contact
      );
    } else {
      return this.apiService.post(
        environment.api,
        this.associatedContact,
        contact
      );
    }
  }

  removeAssociatedContact(id: number): Observable<any> {
    return this.apiService.delete(
      environment.api,
      `${this.associatedContact}/${id}`
    );
  }

  getOrganization(id: number): Observable<Organization> {
    return this.apiService.get(environment.api, this.path + id).pipe(
      switchMap((response: Organization) => {
        return of(completeOrganizationInfo(response));
      })
    );
  }

  getOrganizationCrews(
    params: any,
    filters: any
  ): Observable<OrganizationCrewListResponse> {
    return this.apiService
      .post(environment.api, `${this.crews}find-all`, filters, params)
      .pipe(
        switchMap((response: OrganizationCrewListResponse) => {
          response.items = completeOrganizationCrewList(response.items);
          return of(response);
        })
      );
  }

  deleteOrganizationCrew(id: number): Observable<any> {
    return this.apiService.delete(environment.api, `${this.crews}${id}`);
  }

  saveOrganizationCrew(
    organizationCrew: OrganizationCrew
  ): Observable<OrganizationCrew> {
    if (organizationCrew.id) {
      return this.apiService.patch(
        environment.api,
        `${this.crews}${organizationCrew.id}`,
        organizationCrew
      );
    } else {
      return this.apiService.post(
        environment.api,
        this.crews,
        organizationCrew
      );
    }
  }

  deleteOrganizationContact(id: number): Observable<any> {
    return this.apiService.delete(
      environment.api,
      `${this.organizationContact}${id}`
    );
  }

  saveOrganizationContact(
    organizationContact: OrganizationContact
  ): Observable<OrganizationCrew> {
    if (organizationContact.id) {
      return this.apiService.patch(
        environment.api,
        `${this.organizationContact}${organizationContact.id}`,
        organizationContact
      );
    } else {
      return this.apiService.post(
        environment.api,
        this.organizationContact,
        organizationContact
      );
    }
  }

  verifySubscription(organizationId: number): Observable<any> {
    return this.apiService.post(environment.api, 'stripe/subscription/verify', {
      organizationId,
    });
  }

  private convertOrgConfigDataToFormConfig(data: any) {
    // no config before
    if(!data) return

    Object.keys(data).forEach((key) => {
      let currConfig = this.orgConfiguration.getValue();

      if(!(key in currConfig)) return;

      currConfig[key].value = data[key];

      this.orgConfiguration.next(currConfig);
    })
  }

  getOrgConfiguration(orgId: number) {
    console.log('Getting organization configuration ...')
    this.apiService
        .get(environment.api, this.path + orgId + this.configuration).subscribe((resp) => {
          this.convertOrgConfigDataToFormConfig(resp.configuration)
          console.log('Successfully getting organization configuration.')
            // console.log(resp)
        })
  }

  extractOrgConfigValue(data?: FormGroup) {
    let extractedValue: any = {}
    if(!data) {
      let currConfig = this.orgConfiguration.getValue();
      Object.keys(currConfig).forEach((val) => extractedValue[val] = currConfig[val].value)
    } else {
      extractedValue = data.value;
    }
    return extractedValue;
  }

  saveOrgConfiguration(orgId: number, data: FormGroup) {
    const configVal = this.extractOrgConfigValue(data);
    return this.apiService.patch(environment.api, this.path + orgId + this.configuration, configVal)
  }
}
