import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface LoaderState {
  show: boolean;
}

@Injectable({ providedIn: 'root' })
export class LoaderService {
  count = 0;
  private loading$ = new Subject<boolean>();

  constructor() { }

  getLoading(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  add(): void {
    this.count = this.count++;
    this.loading$.next(true);
  }

  hide(): void {
    this.count = this.count--;
    if (this.count === 0) {
      this.loading$.next(false);
    }
  }

}
