import {GeneralEmployee, ROLES, User, UserStatus} from "../model/user";
import {isNotEmpty, keysToCamel} from "../../shared/common-services/utils";

export const getWelcomePage = (user: User): string => {

  if(user.currentOrganization) {
    return '/dashboard/home'
  }

  switch (user.status) {
    case UserStatus.Active:
      return '/dashboard/home'
    case UserStatus.Inactive:
      return '/inactive/'
    case UserStatus.PendingInformation:
      return '/welcome'
    case UserStatus.PendingVerification:
      return '/dashboard/home'
    default:
      return '/';
  }
};

export const getRole = (user: User): string => {
  if(!user.currentOrganization) {
    throw new Error('User without organization!');
  }

  switch (user.currentOrganization!.role) {
    case ROLES.Master:
      return user.currentOrganization!.organization.createdBy === user.id ?
        "Super Admin" : "Office User";
    case ROLES.Employee:
      return GeneralEmployee;
    case ROLES.Foreman:
      return ROLES.Foreman.toString()
    default:
      return user.currentOrganization!.role;
  }
}

export const completeInfoUser = (user: User): User => {
  user.fullName = `${user.firstName} ${user.lastName}`;
  user.officeUser = false;

  if(user.organizationUser && user.organizationUser.length) {
    const mainOrganizations = (user.organizationUser||[]).filter(x => !x.organization?.isShadowOrganization);
    user.currentOrganization = mainOrganizations?.length ? mainOrganizations[0] : user.organizationUser[0];
    user.officeUser = user.currentOrganization.role === ROLES.Master && user.currentOrganization.isEnabled

    user.role = getRole(user);
  }

  user.fullProfile = isNotEmpty(user.firstName) && isNotEmpty(user.lastName) && isNotEmpty(user.email) &&
    !!user.userProfile && isNotEmpty(user.userProfile.phone) && isNotEmpty(user.userProfile.address) &&
    isNotEmpty(user.userProfile.city) && isNotEmpty(user.userProfile.zip) && isNotEmpty(user.userProfile.state);


  user.welcomePage = getWelcomePage(user);

  return user;
};

export const completeUserList = (list: User[]): User[] => {
  const response: User[] = [];
  list.forEach((user: User) => {
    response.push(completeInfoUser(keysToCamel(user)));
  });
  return response;
};

