import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpHeaders, HttpParams, HttpRequest, HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, timeout} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {LoaderService} from './loader.service';

const CONTENT_TYPE_HEADER = 'Content-Type';
const CONTENT_TYPE_DEFAULT = 'application/json';

@Injectable({providedIn: 'root'})
export class ApiService {

  constructor(
    private http: HttpClient,
    private loader: LoaderService
  ) {
  }

  getBlob(url: string): Observable<any> {
    const reqOpts = {headers: this.getHeaders(), responseType: 'blob' as 'json',};
    return this.http.get(`${url}`, reqOpts);
  }

  async downloadFile(firebaseUrl: string, fileName: string): Promise<void> {
    const response = await fetch(firebaseUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const blob = await response.blob();
    const contentType = response.headers.get("content-type") || "application/octet-stream";
    const newBlob = new Blob([blob], {type: contentType});
    let a = document.createElement('a');
    const url = window.URL.createObjectURL(newBlob);
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 100);
  }

  get(apiUrl: string, path: string, params?: any): Observable<any> {
    const reqOpts = {headers: this.getHeaders(), params: {}};
    if (params) {
      reqOpts.params = this.setQueryParams(params);
    }
    this.loader.add();
    return this.http.get(`${apiUrl}${path}`, reqOpts).pipe(
      timeout(environment.timeout),
      catchError(err => throwError(err)),
      finalize(() => {
        this.loader.hide();
      })
    );
  }

  post(apiUrl: string, path: string, body: any, params = {}): Observable<any> {
    const reqOpts = {headers: this.getHeaders(), params: this.setQueryParams(params)};
    this.loader.add();

    return this.http.post(
      `${apiUrl}${path}`,
      JSON.stringify(body),
      reqOpts,
    ).pipe(
      timeout(environment.timeout),
      catchError(err => throwError(err)),
      finalize(() => {
        this.loader.hide();
      })
    );
  }

  put(apiUrl: string, path: string, body: any): Observable<any> {
    const reqOpts = {headers: this.getHeaders()};
    this.loader.add();

    return this.http.put(
      `${apiUrl}${path}`,
      JSON.stringify(body),
      reqOpts,
    ).pipe(
      timeout(environment.timeout),
      catchError(err => throwError(err)),
      finalize(() => {
        this.loader.hide();
      })
    );
  }

  delete(apiUrl: string, path: string): Observable<any> {
    const idLoader: string = this.getIdLoader(path);
    this.loader.add();
    return this.http.delete(
      `${apiUrl}${path}`,
      {headers: this.getHeaders(), withCredentials: true},
    ).pipe(
      timeout(environment.timeout),
      catchError(err => throwError(err)),
      finalize(() => {
        this.loader.hide();
      }),
    );
  }

  patch(apiUrl: string, path: string, body?: object): Observable<any> {
    const reqOpts = {headers: this.getHeaders()};
    const idLoader: string = this.getIdLoader(path);
    this.loader.add();

    return this.http.patch(
      `${apiUrl}${path}`,
      JSON.stringify(body),
      reqOpts,
    ).pipe(
      timeout(environment.timeout),
      catchError(err => throwError(err)),
      finalize(() => {
        this.loader.hide();
      }),
    );
  }

  public getHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    return headers
      .set(CONTENT_TYPE_HEADER, CONTENT_TYPE_DEFAULT);
  }

  /**
   * To be used as 'ID' in the loader service
   */
  private getIdLoader(path: string): string {
    return `${path}`;
  }

  private setQueryParams(params: any): HttpParams {
    let queryParams = new HttpParams();
    Object.keys(params).forEach((param) => {
      if (params[param] != null && (params[param] || (params[param]) >= 0)) {
        queryParams = queryParams.append(param, params[param]);
      }
    });
    return queryParams;
  }

  submitWithFile(httpMethod: string, path: string, form: any): Observable<any> {
    const req = new HttpRequest(httpMethod, path, form, {
      reportProgress: false
    });
    return this.http
      .request(req)
      .pipe(filter((e: any) => e instanceof HttpResponse));

  }
}
