import {User} from "../../auth/model/user";
import {BillableUnit} from "./billable-units";
import {InventoryUnit} from "./inventory-units";
import {Organization} from "./organization";
import {Document} from "./document";
import {WorkOrderNote} from "./work-order-note";

export enum WorkOrderStatus {
  Queued = "Queued",
  InProgress = "InProgress",
  Submitted = "Submitted",
  SentForPayment = "SentForPayment",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Closed = "Closed",
  Paid = "Paid",
  Archived = "Archived",
}

export interface WorkOrderCreate {
  id?: number;
  workSourceId?: number;
  assignedOrganizationId: number;
  createdBy?: number; // who is calling the endpoint
  updatedBy?: number; // who is updating the work order
  status?: WorkOrderStatus;
  workOrderId?: string;
  contactName?: string;
  projectName?: string;
  carrierId?: number;
  dueDate?: Date;
  workAddress: string;
  workCity: string;
  workState: string;
  workZip: string;
  latitude?: string;
  longitude?: string;
  phoneNumber?: string;
  description?: string;
  workOrderTypeIds?: number[];
  workOrderUserIds?: number[]; // must belong to the organization set by assignedOrganizationId
  crewWorkTypeIds?: ConnectCrewWorkTypeCreate[];
  isRejected?: boolean;
  deletedAt?: Date;
  hiringOrganization?: number;
  priority?: WorkOrderPriority;
}

export class ConnectCrewWorkTypeCreate {
  crewId: number;
  workTypeIds?: number[];
}

export interface WorkOrder {
  id: number;
  workSourceId: string,
  assignedOrganizationId: number,
  createdBy: User,
  status: WorkOrderStatus,
  workOrderId: string,
  contactName: string,
  phoneNumber: string;
  projectName?: string,
  carrierId: number,
  carrier?: Carrier,
  dueDate: string,
  workAddress: string,
  workCity: string,
  workState: string,
  workZip: string,
  description: string,
  latitude: string,
  longitude: string,
  createdAt: string,
  updatedAt?: string
  organization: Organization;
  priority?: WorkOrderPriority;
  additionalTextInInvoiceEmail?: string;

  workOrderWorkType: WorkOrderWorkType[];
  workOrderUser: WorkOrderUser[];
  assignedUsers: AssignedUser[]; //office workers assigned
  workOrderBillableUnit: WorkOrderBillableUnit[];
  workOrderInventoryUnit: WorkOrderInventoryUnit[];
  document: Document[];
  workOrderNote: WorkOrderNote[];

  workSourceParent?: Partial<WorkOrder>;
  workOrderCrew?: any[];

  //front
  checked?: boolean;
  workOrderTypeIds: number[];
  workOrderUserIds: number[];
  crewWorkTypeIds: any[];
  dueDateObj: Date;
  statusColor: string;
  fullAddress: string;
  workOrderBillableUnitTotal: number;
  workOrderInventoryUnitTotal: number;
  workSourceChilds: any[];
  contractors: number[];
  flagColor: string;
  workTypes?: string;
  statusText: string;
  hiringOrganization?: number;
  fieldUsers: AssignedUser[]; // crew leads

}

export class Carrier {
  id: number;
  name: string;
}


export class WorkOrderListResponse {
  items: WorkOrder[];
  total: number;
}

export class WorkTypeListResponse {
  items: WorkType[];
  total: number;
}

export interface Invoice {
  id: number;
}


export interface WorkType {
  id: number;
  workType: string,
  description: string,
  organizationId: number;
  status?: "Active" | "Inactive";

  //front
  total?: number;
  label?: string;
}

export interface Equipment {
  id: number;
  equipment: string,
  description: string,
}

export interface Skill {
  id: number;
  skill: string,
  description: string,
}

export interface WorkOrderWorkType {
  id: number,
  workOrderId: number,
  workTypeId: number,
  workType: WorkType,
}


export interface WorkOrderUser {
  id: number,
  workOrderId: number,
  userId: number,
  user: User;
}

export interface WorkOrderBillableUnit {
  id: number;
  quantity: number;
  beginFootage: number;
  endFootage: number;
  billableUnit: BillableUnit;
  totalPrice: number;
  createdBy: number;
}

export interface WorkOrderInventoryUnit {
  id: number;
  quantity: number;
  beginFootage: number;
  endFootage: number;
  reelNumber?: string;
  inventoryUnit: InventoryUnit;
  createdBy: number;
}

export interface AssignedUser {
  id: number,
  avatar: string,
  email: string,
  text: string,
  image?: string,
  isDeleted: boolean,
  isEnabled?: boolean,
}


export interface AssociatedContact {
  id: number;
  organizationId: number;
  organizationName: string;
  owner: number;
  status: string;
  isCarrier: boolean;
  isActive: boolean;
  //front
  organizationNameStatus?: string;
  disabled?: boolean;
  OrganizationB?: Organization;
  numActiveWorkOrders?: number;
}

export interface AssociatedContactInfo {
  id: number;
  associatedContactAId: number;
  associatedContactBId: number;
  status: string;
  description: string;
  isCarrier: boolean;
}

export enum AssociatedContactStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Denied = 'Denied'
}

export enum WorkOrderPriority {
  Emergency = 'Emergency',
  Medium = 'Medium',
  Low = 'Low'
}
