import {Component, Inject} from '@angular/core';
import {CredentialsService} from "./auth/services/credentials.service";
import {LoginService} from "./auth/services/login.service";
import {DOCUMENT} from "@angular/common";
import {environment} from "../environments/environment";
import {StorageService} from "./shared/services/storage/storage.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor() {

  }



}
