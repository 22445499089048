import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  Credentials,
  CredentialsService,
  TeamAccess,
} from './credentials.service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../shared/common-services/api.service';
import { OauthTokenRequest, User } from '../model/user';
import { keysToCamel } from '../../shared/common-services/utils';
import { completeInfoUser } from './user.utils';
import { HideService } from 'src/app/shared/services/hide-information/hide-information.service';

@Injectable({ providedIn: 'root' })
export class LoginService {
  signUpInfo?: any;

  signUpPath = 'auth/signup';
  loginPath = 'auth/login';
  logoutPath = 'auth/logout';
  profilePath = 'auth/me';
  refreshPath = 'auth/refreshtoken';
  passwordPath = 'auth/updatepassword';
  userPath = 'user/';
  userProfilePath = 'user-profile/';
  emailVerifyPath = 'auth/email/verify/';
  forgotPasswordPath = 'auth/email/forgot-password/';
  resetPasswordPath = 'auth/email/reset-password/';
  resendVerificationPath = 'auth/email/resend-verification/';

  constructor(
    private apiService: ApiService,
    private credentialsService: CredentialsService,
    private hideService: HideService
  ) {}

  getCurrentUser(): User {
    if (this.credentialsService.user) {
      return this.credentialsService.user;
    } else {
      this.doLogout(this.credentialsService.credentials);
      this.hideService.removeToHideLocalStorage();
      throw new Error('No user logged');
    }
  }

  doLogin(payload: OauthTokenRequest): Observable<User> {
    this.credentialsService.setUser();
    return this.apiService.post(environment.api, this.loginPath, payload).pipe(
      switchMap((credentials: Credentials) => {
        credentials.email = payload.email;
        this.credentialsService.setCredentials(credentials);
        return this.getProfile();
      })
    );
  }

  signUp(payload: OauthTokenRequest): Observable<any> {
    return this.apiService.post(environment.api, this.signUpPath, payload);
  }

  verifyEmail(token: string): Observable<any> {
    return this.apiService.get(environment.api, this.emailVerifyPath + token);
  }

  forgotPassword(email: string): Observable<any> {
    return this.apiService.post(environment.api, this.forgotPasswordPath, {
      email,
    });
  }

  setCredentialAndProfile(credentials: Credentials): Observable<any> {
    this.credentialsService.setCredentials(credentials);
    return this.getProfile();
  }

  getProfile(): Observable<User> {
    return this.apiService.get(environment.api, this.profilePath).pipe(
      switchMap((user: User) => {
        const u = completeInfoUser(keysToCamel(user));
        this.credentialsService.setUser(u);
        return of(u);
      })
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  doLogout(credentials?: Credentials | null): Observable<boolean> {
    return this.apiService.post(environment.api, this.logoutPath, credentials);
  }

  refreshToken(): Observable<any> {
    const credentials = this.credentialsService.credentials;
    if (credentials && credentials.email && credentials.refreshToken) {
      return this.apiService
        .post(environment.api, this.refreshPath, credentials)
        .pipe(
          switchMap((c: Credentials) => {
            credentials.accessToken = c.accessToken;
            credentials.refreshToken = c.refreshToken;
            this.credentialsService.setCredentials(credentials);
            return this.getProfile();
          })
        );
    }
    return of(credentials);
  }

  updatePassword(data: object): Observable<any> {
    return this.apiService.patch(environment.api, this.passwordPath, data);
  }

  resetPassword(resetToken: string, password: string): Observable<any> {
    return this.apiService.post(environment.api, this.resetPasswordPath, {
      password,
      resetToken,
    });
  }

  updateUser(id: number, info: any): Observable<any> {
    return this.apiService.patch(environment.api, this.userPath + id, info);
  }

  updateUserAndProfile(id: number, user: any, profile: any): Observable<any> {
    return this.apiService.patch(
      environment.api,
      this.userPath + 'profile/' + id,
      { user, profile }
    );
  }

  updateProfilePicture(info: any, idProfile?: number): Observable<any> {
    let sub;
    if (idProfile) {
      sub = this.apiService.submitWithFile(
        'PATCH',
        environment.api + this.userProfilePath + idProfile,
        info
      );
    } else {
      sub = this.apiService.submitWithFile(
        'POST',
        environment.api + this.userProfilePath,
        info
      );
    }

    return sub.pipe(
      switchMap((r) => {
        return this.getProfile();
      })
    );
  }

  updateProfile(profile: any, info: any): Observable<any> {
    if (profile) {
      return this.apiService.patch(environment.api, this.userProfilePath, info);
    } else {
      return this.apiService.post(environment.api, this.userProfilePath, info);
    }
  }

  resendVerification(email: string): Observable<any> {
    return this.apiService.post(
      environment.api,
      this.resendVerificationPath,
      email
    );
  }
}
